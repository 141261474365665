import { useServices } from "context/services";
import { useLazyQuery, useQuery } from "@apollo/client";
import { FXD_POOLS, FXD_POSITIONS, FXD_STATS, FXD_USER } from "apollo/queries";
import { useCallback, useEffect, useState } from "react";
import { COUNT_PER_PAGE } from "utils/Constants";
import useSyncContext from "context/sync";
import useConnector from "context/connector";
import { ZERO_ADDRESS } from "fathom-sdk";

const useDashboardContext = () => {
  const { positionService } = useServices();
  const { account, chainId } = useConnector();
  const { syncFXD, prevSyncFxd } = useSyncContext();

  const [, { refetch: refetchStats, loading: loadingStats }] = useLazyQuery(
    FXD_STATS,
    {
      context: { clientName: "stable", chainId },
      variables: { chainId },
    }
  );
  const [
    loadPositions,
    {
      refetch: refetchPositions,
      loading: loadingPositions,
      called: calledPositions,
      data: positionsData,
      fetchMore: fetchMorePositions,
    },
  ] = useLazyQuery(FXD_POSITIONS, {
    context: { clientName: "stable", chainId },
    variables: { chainId },
  });

  const [
    loadAllPositions,
    { refetch: refetchAllPositions, data: allPositionsData },
  ] = useLazyQuery(FXD_POSITIONS, {
    fetchPolicy: "no-cache",
    context: { clientName: "stable", chainId },
    variables: { chainId, first: 1000, skip: 0 },
  });

  const {
    data: poolsData,
    refetch: refetchPools,
    loading: loadingPools,
  } = useQuery(FXD_POOLS, {
    context: { clientName: "stable", chainId },
    variables: { chainId },
  });

  const [positionCurrentPage, setPositionCurrentPage] = useState<number>(1);
  const [positionsItemsCount, setPositionsItemsCount] = useState<number>(0);
  const [proxyWallet, setProxyWallet] = useState<string>("");

  const [
    loadUserStats,
    { refetch: refetchUserStats, loading: loadingUserStats },
  ] = useLazyQuery(FXD_USER, {
    context: { clientName: "stable", chainId },
    fetchPolicy: "network-only",
    variables: { chainId },
  });

  const fetchUserStatsAndProxyWallet = async () => {
    const proxyWallet = await positionService.getProxyWallet(account);
    setProxyWallet(proxyWallet);
    /**
     * Load all positions for the user
     */
    loadAllPositions({
      variables: {
        walletAddress: proxyWallet,
      },
    });

    return loadUserStats({
      variables: {
        walletAddress: proxyWallet,
        chainId,
      },
    }).then((response) => {
      const { data } = response;
      data &&
        Array.isArray(data.users) &&
        setPositionsItemsCount(data.users[0]?.activePositionsCount || 0);
    });
  };

  const fetchProxyWallet = useCallback(async () => {
    const newProxyWallet = await positionService.getProxyWallet(account);
    setProxyWallet(newProxyWallet);
  }, [setProxyWallet, account]);

  const refetchData = useCallback(async () => {
    refetchStats({
      chainId,
    });
    refetchPools({
      chainId,
    });

    let currentProxyWallet = proxyWallet;

    if (!proxyWallet || proxyWallet === ZERO_ADDRESS) {
      currentProxyWallet = await positionService.getProxyWallet(account);
      setProxyWallet(currentProxyWallet);
    }

    refetchPositions({
      walletAddress: currentProxyWallet,
      first: COUNT_PER_PAGE,
      skip: 0,
      chainId,
    }).then(() => {
      setPositionCurrentPage(1);
    });

    refetchAllPositions({
      walletAddress: currentProxyWallet,
    });

    refetchUserStats({
      walletAddress: currentProxyWallet,
      chainId,
    }).then(({ data: { users } }) => {
      if (users !== undefined && users.length > 0) {
        const itemsCount = users[0].activePositionsCount;
        setPositionsItemsCount(itemsCount);
      }
    });
  }, [
    account,
    chainId,
    positionService,
    proxyWallet,
    refetchPools,
    refetchStats,
    refetchPositions,
    refetchAllPositions,
    refetchUserStats,
  ]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (account && chainId) {
      timeout = setTimeout(() => {
        fetchUserStatsAndProxyWallet();
      }, 100);
    } else {
      setProxyWallet("");
      setPositionsItemsCount(0);
    }

    return () => timeout && clearTimeout(timeout);
  }, [chainId, account, setPositionsItemsCount, setProxyWallet]);

  useEffect(() => {
    if (syncFXD && !prevSyncFxd) {
      refetchData();
    }
  }, [syncFXD, prevSyncFxd, refetchData]);

  return {
    loadPositions,
    loadingPositions,
    fetchMorePositions,
    calledPositions,
    positionsData,
    poolsData,
    allPositionsData,
    loadingUserStats,
    loadingStats,
    loadingPools,
    proxyWallet,
    positionCurrentPage,
    positionsItemsCount,
    setPositionCurrentPage,
    fetchProxyWallet,
  };
};

export default useDashboardContext;
