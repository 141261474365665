import { Currency, Token, Trade } from "into-the-fathom-swap-sdk";
import { useCallback, useEffect, useState } from "react";
import { Field } from "apps/dex/state/swap/actions";
import usePricesContext from "context/prices";
import BigNumber from "bignumber.js";

const tokenPrices = new Map<string, number>();

const findToken = (tokens: Token[], tokenSymbol: string) => {
  return ["xdc", "wxdc"].includes(tokenSymbol.toLowerCase())
    ? tokens.find((token) =>
        ["xdc", "wxdc"].includes(token.symbol?.toLowerCase() as string)
      )
    : tokens.find(
        (token) => token.symbol?.toLowerCase() === tokenSymbol?.toLowerCase()
      );
};

export const useTokenPricesForTrade = (
  trade: Trade | undefined,
  currencies: { [field in Field]?: Currency }
) => {
  const [token0Price, setToken0Price] = useState<string>("0");
  const [token1Price, setToken1Price] = useState<string>("0");
  const [isPriceLoading, setIsPriceLoading] = useState<boolean>(false);
  const { xdcPrice, eursPrice, wtkPrice } = usePricesContext();

  const fetchPriceFeedByContractAddress = useCallback(
    async (contractAddress: string, symbol: string) => {
      if (["xdc", "wxdc"].includes(symbol.toLowerCase())) {
        return Promise.resolve(
          BigNumber(xdcPrice)
            .dividedBy(10 ** 18)
            .toString()
        );
      } else if (symbol.toLowerCase() === "eurs") {
        return Promise.resolve(
          BigNumber(eursPrice)
            .dividedBy(10 ** 18)
            .toString()
        );
      } else if (symbol.toLowerCase() === "wtk") {
        return Promise.resolve(
          BigNumber(wtkPrice)
            .dividedBy(10 ** 18)
            .toString()
        );
      } else {
        return fetch(
          `${process.env.REACT_APP_PRICE_FEED_CONTRACT_ADDRESS}?contract_addresses=${contractAddress}` as string
        )
          .then((data) => data.json())
          .then((data) => {
            return Object.values(data).length
              ? // @ts-ignore
                Object.values(data)[0]["usd"].toString()
              : "0";
          });
      }
    },
    [xdcPrice, eursPrice]
  );

  useEffect(() => {
    if (trade?.route?.pairs?.length) {
      const tokens: Token[] = [];
      trade.route.pairs.forEach((pair) => {
        tokens.push(pair.token0, pair.token1);
      });
      const token0Address =
        findToken(tokens, currencies.INPUT?.symbol as string)?.address.replace(
          "0x",
          "xdc"
        ) || "";
      const token1Address =
        findToken(tokens, currencies.OUTPUT?.symbol as string)?.address.replace(
          "0x",
          "xdc"
        ) || "";
      if (tokenPrices.has(token0Address) && tokenPrices.has(token1Address)) {
        setToken0Price(tokenPrices.get(token0Address)?.toString() as string);
        setToken1Price(tokenPrices.get(token1Address)?.toString() as string);
      } else if (!isPriceLoading) {
        setIsPriceLoading(true);
        Promise.all([
          fetchPriceFeedByContractAddress(
            token0Address,
            currencies.INPUT?.symbol as string
          ),
          fetchPriceFeedByContractAddress(
            token1Address,
            currencies.OUTPUT?.symbol as string
          ),
        ])
          .then(([token0Price, token1Price]) => {
            setToken0Price(token0Price);
            setToken1Price(token1Price);

            tokenPrices.set(token0Address, token0Price);
            tokenPrices.set(token1Address, token1Price);
          })
          .finally(() => {
            setIsPriceLoading(false);
          });
      }
    } else {
      setToken0Price("0");
      setToken1Price("0");
    }
  }, [
    trade?.route,
    currencies,
    fetchPriceFeedByContractAddress,
    setIsPriceLoading,
  ]);

  return {
    token0Price,
    token1Price,
  };
};
