import { useQuery } from "@apollo/client";
import { FXD_STATS } from "apollo/queries";
import useConnector from "context/connector";
import usePoolsList from "hooks/Pools/usePoolsList";
import { useMemo } from "react";
import { ICollateralPool } from "fathom-sdk";
import useDashboard from "context/fxd";
import BigNumber from "bignumber.js";

const useProtocolStats = () => {
  const { chainId } = useConnector();
  const { data, loading, refetch } = useQuery(FXD_STATS, {
    context: { clientName: "stable", chainId },
    variables: { chainId },
    fetchPolicy: "network-only",
  });

  const { allPositionsData } = useDashboard();
  const { pools } = usePoolsList();

  const totalBorrowed = useMemo(() => {
    if (pools.length) {
      return pools.reduce(
        (accumulator: number, currentPool: ICollateralPool) => {
          return accumulator + Number(currentPool.totalBorrowed);
        },
        0
      );
    } else {
      return 0;
    }
  }, [pools]);

  const userBorrowed = useMemo(() => {
    if (allPositionsData && allPositionsData.positions.length) {
      return allPositionsData.positions.reduce(
        (accumulator: number, currentPosition) => {
          return accumulator + Number(currentPosition.debtValue);
        },
        0
      );
    } else {
      return 0;
    }
  }, [allPositionsData]);

  const userCollateral = useMemo(() => {
    if (allPositionsData && allPositionsData.positions.length) {
      return allPositionsData.positions.reduce(
        (accumulator: number, currentPosition) => {
          const findPool = pools.find((pool) => {
            return pool.id === currentPosition.collateralPool;
          }) as ICollateralPool;

          return (
            accumulator +
            BigNumber(currentPosition.lockedCollateral)
              .multipliedBy(findPool.collateralPrice)
              .toNumber()
          );
        },
        0
      );
    } else {
      return 0;
    }
  }, [allPositionsData]);

  return {
    userBorrowed,
    userCollateral,
    totalSupply: data?.protocolStat?.totalSupply || 0,
    totalBorrowed,
    tvl: data?.protocolStat?.tvl || 0,
    loading,
    refetch,
  };
};

export default useProtocolStats;
