import { useCallback, useMemo, useState } from "react";
import { ICollateralPool } from "fathom-sdk";
import useConnector from "context/connector";
import useDashboard from "context/fxd";

const usePoolsList = () => {
  const { poolsData, loadingPools } = useDashboard();

  const [selectedPool, setSelectedPool] = useState<ICollateralPool>();
  const { chainId } = useConnector();

  const onCloseNewPosition = useCallback(() => {
    setSelectedPool(undefined);
  }, [setSelectedPool]);

  const filteredPools = useMemo(() => {
    if (!loadingPools && poolsData && poolsData.pools.length) {
      return poolsData.pools.map((poolItem: ICollateralPool) => {
        return poolItem;
      });
    } else {
      return [];
    }
  }, [poolsData, loadingPools, chainId]);

  return {
    pools: filteredPools,
    selectedPool,
    onCloseNewPosition,
    setSelectedPool,
  };
};

export default usePoolsList;
